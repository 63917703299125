/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

"use strict"

require("firebase/auth")

require("firebase/firestore")

require("firebase/storage")

// You can delete this file if you're not using it
