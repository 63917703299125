module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"gatsby-starter-default","short_name":"starter","start_url":"/","background_color":"#663399","theme_color":"#663399","display":"minimal-ui","icon":"src/images/gatsby-icon.png"},
    },{
      plugin: require('../node_modules/gatsby-plugin-firebase/gatsby-browser.js'),
      options: {"plugins":[],"features":{"analytics":true,"performance":true},"credentials":{"apiKey":"AIzaSyDy3yHofHWr6ZZl4A1UP72fXkaBPp9BtxI","authDomain":"east-coast-pizza.firebaseapp.com","databaseURL":"https://east-coast-pizza.firebaseio.com","projectId":"east-coast-pizza","storageBucket":"east-coast-pizza.appspot.com","messagingSenderId":"396628438822","appId":"1:396628438822:web:8ce99bd455174e7e469be7","measurementId":"G-343SJWF1HZ"}},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"G-WRCM3NPDFF"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
